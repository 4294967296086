// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._404_cta_38I16{background-color:#e8e2e6;border-radius:2px;color:#554350;display:inline-block;font-size:1.8em;font-weight:500;margin-top:27px;padding:9px 20px;text-decoration:none}._404_cta_38I16:hover{background-color:#fff}", ""]);
// Exports
exports.locals = {
	"cta": "_404_cta_38I16"
};
module.exports = exports;
