<template>
  <Section>
    <H>404</H>
    <H :level="2">Not found</H>
    <P>The page you are looking for does not exist.</P>
    <router-link :class="$style.cta" to="/">Go home</router-link>
  </Section>
</template>

<script>
import H from '@/components/H'
import P from '@/components/P'
import Section from '@/components/Section'

export default {
  name: 'NotFound',

  components: {
    H,
    P,
    Section,
  },

  metaInfo() {
    return {
      meta: [{ name: 'description', content: '404 - Not found' }],
      title: '404 - Not found',
    }
  },
}
</script>

<style module lang="scss">
.cta {
  background-color: #e8e2e6;
  border-radius: 2px;
  color: #554350;
  display: inline-block;
  font-size: 1.8em;
  font-weight: 500;
  margin-top: 27px;
  padding: 9px 20px;
  text-decoration: none;
}

.cta:hover {
  background-color: #fff;
}
</style>
